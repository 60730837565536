import React from 'react'
import {  Row, Col } from 'react-bootstrap'
import {Nav  } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { Container, ListGroup, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (

        <>
        
        <footer className="footer">
        

        <Container>

        <hr/>

        <Row>
          <Col>
          <Link to='/termsandconditions'>
                    
                    <span style={{color:'white'}}>Terms and Conditions</span>
                  
            </Link>
          
          </Col>
          <Col>
          <LinkContainer to='/privacypolicy'>
                <Nav.Link> 
                <span style={{color:'white'}}>Privacy Policy</span>
                </Nav.Link>
            </LinkContainer>
          
          </Col>
          <Col>
          
             
              <span style={{color:'white'}}>biz@pixagan.com</span>
             
            
         
          </Col>
        </Row>

        <hr/>

        <Row>
          <Col className='text-center py-3 mt-0'>
          <span> &copy; 2024-, Pixagan Technologies Private Limited. All rights reserved. | </span>
          </Col>
        </Row>

        <hr />

        </Container>





        </footer>
        
        </>


        )
    }
    
    export default Footer
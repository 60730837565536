import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import Moment from 'react-moment';




export const QuestionItem = ({question}) => {

    const dispatch = useDispatch()

    const notebookR = useSelector(state => state.notebookR)
    const { sessionMode } = notebookR


    const [showSolutionMode, setShowSolutionMode] = useState(false)

    const [textAnswer, setTextAnswer] = useState('')

    useEffect(() => {
        


        
    }, [])

    const [solution, setSolution] = useState("0")

    const [rightOrWrong, setRightWrong] = useState("")
    const [isAnswered, setIsAnswered] = useState(false)
    

    const updateSolution = () => {

    }

    const tryQuestion = () => {

        if(question.questionType == 'mcq' && question.answerMCQ){
            if(parseInt(solution)+1 == parseInt(question.answerMCQ)){
                setRightWrong('Correct')
                setIsAnswered(true)
                
            }else{
                setRightWrong('Wrong. Try again.')
                setIsAnswered(true)
                
            }
        }


    }


    var optionlabels = ['a', 'b', 'c', 'd', 'e', 'f']


    return (

        <>
        
            {question && question.question && question.question.map((ctext, index)=>(
                <p style={{fontWeight:'600'}}>{ctext}</p>
            ))}

            
            <Row>
            {Array(parseInt(question.nOptions)).fill(0).map((val, index) =>(
                <Col style={{minWidth:'40vw'}}>
                    <Form.Check
                    type="radio"
                    label={optionlabels[index] + ')' + question.options[index]  }
                    name={"formHorizontalRadios" + "_" + question._id.toString() + "_"+ index}
                    id={"formHorizontalRadios1"  + "_" + question._id.toString() + "_"+ index}
                    onChange={()=>setSolution(index)}
                    checked={solution==index ? true : false}
                    //readOnly={true}
                    />

                </Col>

            ))}
             </Row>

                 <Button style={{borderRadius:'20px', backgroundColor:"#8b008b", textAlign:'center', paddingTop:'2px', paddingBottom:'2px', marginTop:'5px'}} onClick={()=>tryQuestion()}>Submit</Button>

             <hr />

                {isAnswered && (
                    <p className='h5' style={{padding:'5px'}}>{rightOrWrong}</p>
                )}


                {showSolutionMode && (
                    <>
                        <Button>Show Solution</Button>

                        {question.questionType == 'mcq' && question.answerMCQ && (
                            <>
                                {question.answerMCQ}
                            </>
                        )}

                        {question.questionType == 'text' && question.answerText && (
                            <>
                                {question.answerText}
                            </>
                        )}

                    </>
                )}
             


            
        
        </>

    )
}


export default QuestionItem

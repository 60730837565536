import axios from 'axios';
import React , {useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Radio, Badge } from 'react-bootstrap'


import {addQuizQuestion, updateQuizQuestion, deleteQuizQuestion} from '../../actions/creatorQuizActions'



import {checkSolution} from '../../actions/quizActions'

import {

  CHECK_SOLUTION,

} from '../../constants/quizConstants'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  deviceDetect
} from "react-device-detect";

export const QuizQuestion = ({ quiz_id, question_in}) => {


  const dispatch = useDispatch()

  const userState = useSelector(state => state.userState)
  const { userInfo } = userState

  var optionlabels = ['a', 'b', 'c', 'd', 'e', 'f']

  const [viewMode, setViewMode] = useState('view')

  const [instructions, setInstructions] = useState('')
    
  const [qtext, setQText] = useState('')

  const [questionType, setQuestionType] = useState('mcq')

  const [nOptions, setnOptions] = useState(4)
    
  //const [options, setOptions] = useState([])

  const [option1, setOption1] = useState("")
  const [option2, setOption2] = useState("")
  const [option3, setOption3] = useState("")
  const [option4, setOption4] = useState("")
  const [option5, setOption5] = useState("")
  const [option6, setOption6] = useState("")

  const [optionin, setOptionin] = useState('')

  const [answer, setAnswer] = useState(-1)


  const [solution, setSolution] = useState("0")

  const [rightOrWrong, setRightWrong] = useState(false)
  const [isAnswered, setIsAnswered] = useState(false)
    

  const submitSolutionHandler = async() => {

    const config = {
      headers: {
          'Content-Type': 'application/json',
      }
    }

    const full_url = `/api/quiz/answer/${question_in._id}`

    const { data } = await axios.post(full_url,{answer},config)

    if(data.question_id == question_in._id){
      if(data.ans == 'Correct'){
        setRightWrong(true)
      }else{
        setRightWrong(false)
      }
      setIsAnswered(true)
    } 
    

    //dispatch(checkSolution(question_in._id, solution))

  }


  useEffect(() => {



  }, []);





  
  return (

    <>
    
        <Card style={{padding:'20px', borderRadius:'20px', marginBottom:'20px'}}>
      
            <>

                <Card.Header style={{borderRadius:'10px'}}>
                  {question_in && question_in.Qtext && question_in.Qtext.map((cquestion, index)=>(
                      <p className='h5' style={{fontWeight:'600'}}>{cquestion.item}</p>
                  ))}
                </Card.Header>
                

                  <Card.Body>
                  {question_in && question_in.qoptions && question_in.qoptions.map((coption, index)=>(
                    
                    <>
                    {/* <p>{coption.text}</p> */}

                    <Form.Check
                    type="radio"
                    label={optionlabels[index] + ')' + coption.text  }
                    name={"formHorizontalRadios" + "_" + question_in._id.toString() + "_"+ index}
                    id={"formHorizontalRadios1"  + "_" + question_in._id.toString() + "_"+ index}
                    onChange={()=>setAnswer(index)}
                    checked={answer==index ? true : false}
                    //readOnly={true}
                    />

                    </>


                ))}



              <div  style={{display: "flex", justifyContent: "left", alignItems: "center", marginTop:'10px'}}>
              <Button style={{maxWidth:isMobile?'40vw':'25vw', borderRadius:'20px', backgroundColor:'#2CCCD6'}} onClick={()=>submitSolutionHandler()}>
                  Submit
              </Button>
              </div>



                <div style={{padding:'10px'}}>

                {isAnswered ==true && (
                  <>
                    {rightOrWrong == true ? (
                      <p className='h5' style={{color:'green'}}>Correct</p>
                    ):
                      <p className='h5' style={{color:'red'}}>Wrong</p>
                    }

                  </>
                )}

                </div>

                


                  </Card.Body>

             

              


            </>
        
   

    </Card>



    
    </>

  );
};

export default QuizQuestion;

import React , {useState, useEffect,  Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Card, Button, Form,  OverlayTrigger, Tooltip, InputGroup, ListGroup, Badge } from 'react-bootstrap'

import Moment from 'react-moment';


export const StorylineCard = ({story_in}) => {

    const dispatch = useDispatch()

    
    useEffect(() => {
        
        
    }, [])



    return (

        <>
        
            <Card style={{margin:'10px', borderRadius:'20px'}}>
                <Card.Header>
                    <p className='h4'><Badge style={{backgroundColor:'#2CCCD6', width:'5px'}}></Badge> <span>{story_in.title}</span></p>
                </Card.Header>

                <Card.Body>
                    <p className='h5'>{story_in.dateString}</p>
                    <p className='h5'>{story_in.people}</p>

                    <p className='h5'>
                        {story_in.summary}
                    </p>

                    {story_in.references && story_in.references.map((cref, index)=>(

                                
                        <a
                        key={index}
                        href={cref}
                        target="_blank"
                        rel="noopener noreferrer"
                        >{cref}</a>


                    ))}
                </Card.Body>

            </Card>
            
            
        
        </>

    )
}


export default StorylineCard

import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const userInfo = useSelector(state => state.userState.userInfo);

  if (!userInfo) {
    // If the user is not logged in, redirect to the login page
    return <Navigate to="/" replace />;
  }

  // If the user is logged in, render the component
  return Component ? <Component {...rest} /> : <Outlet />;
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
};

export default PrivateRoute;
